body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

@font-face {
  font-family: "Google Sans";
  src: url("./fonts/GoogleSans/GoogleSans-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Google Sans";
  src: url("./fonts/GoogleSans/GoogleSans-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Google Sans";
  src: url("./fonts/GoogleSans/GoogleSans-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Google Sans";
  src: url("./fonts/GoogleSans/GoogleSans-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Google Sans";
  src: url("./fonts/GoogleSans/GoogleSans-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

code {
  font-family: "Google Sans", Quicksand, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
